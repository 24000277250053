import React, { Component } from 'react'
// import { Auth } from "aws-amplify";
import Select from './../common/select2';
import { HTTPService } from "./../../services/httpService";

const API = new HTTPService();


export default class componentName extends Component {

  state = {
    companies : [],
    selectedCo : this.props.title
  }


  readCompanies = async (user) => {
    let companyList = `/userAccess/${user}`;        
    const  { data : companies }  =  await API.get(companyList);
    this.setState({ companies })
  }

  handleCoChange = async (event) => {

    const { value } = event.target;
    if (value !== ""){
    // find the location name in the array of companies and call the onSwitch
    const selectedCompany = this.state.companies.filter(co => co.Installer_Name === value);
    console.log ("Selected Company : " , selectedCompany);
    
    this.props.onSwitch(selectedCompany[0].Installer_id);

//    this.setState({ });
    }
}

   async componentDidMount() {
      
    if ((this.state.companies.length === 0)){
    let companyList = await this.readCompanies(this.props.userId);
   }
  }

  // Lets count how many companies this user is connected to and if it is more than one, offer the selection as a drop down 
  // if its only 1, then just display it 
  // if they change the selection, then we bounce back up to reset the app with the new selected company 

  render() {

    var companyName = this.props.title;
    const dispCompanies = this.state.companies.map(co => co.Installer_Name);
    return (
      <React.Fragment>
        <nav className="navbar bg-white shadow-sm navbar-light mb-4 d-none d-lg-block">
          <div className="row">
            <div className="col-md-4">
        {this.state.companies.length < 2 && <span className="navbar-brand">{companyName}</span>}
        {this.state.companies.length >= 2 && 
            <Select
                name="location"
                label=" "
                options={dispCompanies}
                value={this.state.selectedCo}
                onChange={this.handleCoChange}
            />}
          </div>
          <div className="col">
          <span className="navbar-text float-right">{this.props.userName}</span>
          </div>
        </div>
        </nav>
      </React.Fragment>
    )
  }
}
//           <button type="button" onClick={() => this.props.onSwitch()} className="btn btn-success m-2 buttons">SWITCH</button>
