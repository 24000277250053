import React, { Component } from 'react';
import JOI from 'joi-browser';
import Select from './common/select';
import { HTTPService } from "./../services/httpService";
import "../assets/css/SearchResult.css";


const API = new HTTPService()



export default class CustomerForm extends Component {
    state = { 
        data : {phone : ""},
        errors : {},
        name : '',
        email : "",
        phone : "",
        found : " ",
        selectable : "Y",
        customerId: "",
        selectedDealer:"Dealerships",
};

     schema = {
        phone: JOI.string().length(10).required().label('phone')}; 

        async componentDidMount () {            

            // load in all the customers for the installer
              const customerRequest =  "/customers/";
              const { data : customers } =  await API.get(customerRequest);
              customers.forEach(function (element) {
                  element.listValue = element.customer_name + " " + element.customer_phone_number;
                });
              this.setState ({ customerList : customers});
              console.log ("componentMount : Customer List ", customers );
          };
          
        async getCustomer(custId) {            

            const customerRequest =  "/customers/"+custId;
            const { data : customer } = await API.get(customerRequest);
            console.log(customer);
            let cust = customer[0];            
            if (customer.length > 0) {
                this.setState( { found : "Y"});
                this.setState( {name : cust.customer_name, email : cust.customer_Email_Address, customerId : cust.customer_id, phone :cust.customer_phone_number});
                this.props.handleCustomer(cust.customer_id, cust.customer_name, "retail");
            } else {
                this.setState( { found : "N"});
            }
            // need to handle the case where this isn't available
          };

          async addCustomer(name, email, phone) {            
            console.log('Adding Customer Record');
            console.log('Record :', name);
            const customer = {
                customerName : name,
                customerEmailAddress : email,
                customerPhoneNumber : phone,
                customerType : "Prospect"
              };
            console.log(customer);
          
            const apiEndpoint =  "/customers";
            const {data:customerId} = await API.post(apiEndpoint, customer);
            console.log(customerId);
            this.setState({customerId});
        };
                  // if the search field value changes, return the list of matching customers  
      handleSearchChange = (value) => {
        console.log("handleSearchChange : ", value);
        this.setState( {input : value});
        this.fetchData(value);
      };
      
      fetchData = (value) => {
        console.log("Customers : fetchData :", this.state.customerList);
        console.log("Customers : value : ", value);
      
        const results = this.state.customerList.filter((user) => {
          return (
            value &&
            user &&
            user.listValue &&
            user.listValue.toLowerCase().includes(value.toLowerCase())
          );
        });
        console.log("Customers : results :", results);
        this.setResults(results);
        if (results.length === 0){
            this.setState({selectable : "N"});
        } else {
            this.setState({selectable : "Y"});
        }
      };

      // if the customer is picked from the list, then that's the record to retrieve
      handlePick = (pick) => {
        console.log ("HandlePick : ", pick);
        this.setState( {input : pick.customer_phone_number, id : pick.customer_id});
        this.setResults([]);
        this.doSubmit(pick.customer_phone_number);
      }
      // go select the information for the selected customer      
      doSubmit = (id) => {
        console.log("go get the detais for :", id);
        // Go and get the customer information from db 
        this.getCustomer(id);
    };

      validate = () => {
        const options = { abortEarly: false };
        const { error } = JOI.validate(this.state.data, this.schema, options);
        if (!error) return null;
    
        const errors = {};
        for (let item of error.details) errors[item.path[0]] = item.message;
        return errors;
      };

      validateProperty = ({ name, value }) => {
        const obj = { [name]: value };
        const schema = { [name]: this.schema[name] };
        const { error } = JOI.validate(obj, schema);
        return error ? error.details[0].message : null;
      };
    
      handleDealerChange = (event) => {
        const { value } = event.target;      
//        console.log("Selected year :", value);
        this.setState( {selectedDealer : value });

        // selecting the dealer will do the same as selecting the customer
        this.setState( { found : "Y"});
        let dealerIndex = this.props.dealerships.findIndex(dealer => dealer.dealer_name === value);

        this.setState( {name : this.props.dealerships[dealerIndex].dealer_name, 
            email : this.props.dealerships[dealerIndex].dealer_email, 
            phone : "",
            customerId : this.props.dealerships[dealerIndex].dealer_id});
        this.props.handleCustomer(this.props.dealerships[dealerIndex].dealer_id, this.props.dealerships[dealerIndex].dealer_name, "whole");

    }

    setResults = (results) => {
        this.setState( {results});
      }

      handleChange = ({ currentTarget: input }) => {
        const errors = { ...this.state.errors };
        console.log ("field validate :", input)
        const errorMessage = this.validateProperty(input);
        if (errorMessage) errors[input.name] = errorMessage;
        else delete errors[input.name];
    
        const data = { ...this.state.data };
        data[input.name] = input.value;
    
        this.setState({ data, errors });
      };

      handleNameChange = (e) => {
        const name = e.currentTarget.value;
        this.setState({name});
      }
      
      handleEmailChange = (e) => {
        const email = e.currentTarget.value;
        this.setState({email});
      }
      
      handlePhoneChange = (e) => {
        const phone = e.currentTarget.value;
        this.setState({phone});
      }
            
            
    doAdd = () => {
        // Go and get the customer information from db 
        var newCustomer = this.addCustomer(this.state.name, this.state.email, this.state.phone);
        // when the promise is resolved, update the state variables
        newCustomer.then(done => {
            this.setState({found : "Y"});
            this.props.handleCustomer(this.state.customerId, this.state.name, "retail");

        })
    };

    doAddWindow = () => {
        this.setResults([]);
        this.setState({found : "N"});
    }
    render() { 
        if (this.props.currentStep !== 1) {
            return null
        };
        const { loading } = this.state;
        var dealerList = this.props.dealerships.map(a=>a.dealer_name);
        dealerList.unshift("Dealerships");

        console.log("Passed Installer :", this.props.installer);
        let showDealer = false;
        let showRetail = false;
        let customerMessage = "Enter the customer name/number";
        if (this.props.installer > ""){
            if (this.props.installer.services.includes("whole") ) {
                showDealer = true;
                customerMessage = "Select the dealership"
             }
            if (this.props.installer.services.includes("retail") ) {
                showRetail = true;
            };
            if (showDealer && showRetail) 
                customerMessage = "Enter the customer name/number OR Select the dealership"
        }
        return (
            <React.Fragment>
                <form className="bg-request p-5">
                <div className="form-row justify-content-md-center">
                    <h5 className="text-center">{customerMessage}</h5>
                </div>
                    {showRetail && <div className="form-row justify-content-md-center search-bar-container">
                          <div className='form-group col-md-3 '>
                              <input
                                    placeholder="Type to search name/number..."
                                    autoFocus
                                    value={this.state.input}
                                    onChange={(e) => this.handleSearchChange(e.target.value)}
                                    onBlur={() => 
                                    setTimeout(() => (
                                        this.setResults([])
                                    ), 100)}
                                />
                            {this.state.results && this.state.results.map((result, i) => 
                              <div key={i} className="search-result" onClick={(e) => this.handlePick(result)}
                              > {result.listValue} </div>
                            )
                            }
                          </div>
                          <div className="form-group col-md-2">
                                <button type="button" className="btn btn-block btn-success buttons" disabled={this.state.selectable === "Y"} onClick={this.doAddWindow}>Add Customer</button>
                            </div>
                    </div>}

                    {showDealer && <div className="form-row justify-content-md-center">
                        <div className="form-group col-md-3 text-center">
                            <Select 
                                 menuPlacement="top"
                                 name="dealerships"
                                label=" "
                                options={dealerList}
                                value={this.state.selectedDealer}
                                onChange={this.handleDealerChange}
                                />
                        </div>
                    </div>}
                </form>

                {this.state.found === "Y" &&<div>
                <h5 className="text-center bold p-5">Customer Selected</h5>
                <div className="col-lg-12 bold text-center">
                <p>{this.state.phone}</p>
                <p>{this.state.name}</p>
                <p>{this.state.email}</p>
                </div></div>}
                
                {this.state.found === "N" && <div className="m-2 shadow">
                <h5 className="text-center bold p-5">Add new Customer Detail</h5>
                <form className="bg-request p-5">
                    <div className="form-row justify-content-md-center">
                        <div className="form-group col-md-2 text-center">
                            <label htmlFor="name" 
                            className="bold mt-1">Full Name</label>
                        </div>
                        <div className="form-group col-md-3">
                            <input type='text' 
                            className="form-control" 
                            name='name' 
                            value={this.state.name} 
                            onChange={this.handleNameChange}/> 
                        </div>
                        </div>
                        <div className="form-row justify-content-md-center">
                        <div className="form-group col-md-2 text-center">
                            <label htmlFor="email" 
                            className="bold mt-1">Email</label>
                        </div>
                        <div className="form-group col-md-3">
                            <input type='text' 
                            className="form-control" 
                            name='email' 
                            value={this.state.email} 
                            onChange={this.handleEmailChange}/> 
                        </div>
                        </div>
                        <div className="form-row justify-content-md-center">
                        <div className="form-group col-md-2 text-center">
                            <label htmlFor="email" 
                            className="bold mt-1">Phone</label>
                        </div>
                        <div className="form-group col-md-3">
                            <input type='text' 
                            className="form-control" 
                            name='phone' 
                            value={this.state.phone} 
                            onChange={this.handlePhoneChange}/> 
                        </div>
                        </div>
                        <div className="form-row justify-content-md-center">
                        <div className="form-group col-md-2">
                            <button type="button" 
                            className="btn btn-block btn-success buttons" 
                            onClick={this.doAdd} 
                            disabled={!this.state.phone}>
                            Add Customer
                        </button>
                        </div>
                    </div>
                </form>
            </div>}

            </React.Fragment>
        )}};


/*                    {showRetail && <div className="form-row justify-content-md-center">
                        <div className="form-group col-md-3">
                            <input type='text' 
                            className="form-control" 
                            autoFocus 
                            name='phone' 
                            value={this.state.data.phone} 
                            onChange={this.handleChange} 
                            error={this.state.errors['phone']} />
                            {this.state.errors['phone'] && <div className="alert alert-danger">{this.state.errors['phone']}</div>}
                        </div>
                        <div className="form-group col-md-2">
                            <button type="button" className="btn btn-block btn-success buttons" onClick={this.doSubmit} disabled={this.validate()}>
                            {loading && <span>
                                        <i
                                        className="fa fa-refresh fa-spin"
                                        />
                                    </span>}
                                    {!loading && <span>Lookup</span>}
                                </button>
                        </div>
                    </div>} */
